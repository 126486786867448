<template>
    <CustomModal :onClose="onClose" title="Die Buchung stornieren?">
        <div class=" confirm-booking-cancel-modal__content">
            {{ `Sie sind gerade dabei, das Lager ${storageName} zu buchen. Sind Sie sicher, dass Sie die Buchung stornieren
            und ein neues
                        Lager auswählen möchten?`}}
        </div>
        <div class="confirm-booking-cancel-modal__footer">
            <button class="confirm-booking-cancel-modal__start-over-button" @click="onBookingCancel()">Buchung neu starten</button>
            <button class="confirm-booking-cancel-modal__finish-button" @click="closeModal()">Buchung fortsetzen</button>
        </div>
    </CustomModal>
</template>
<script>
import CustomModal from "./CustomModal.vue";

export default {
    components: {
        CustomModal
    },
    props: {
        onBookingCancel: { type: Function, required: true },
        closeModal: { type: Function, required: true },
        storageName: { type: String, required: true }
    },
}
</script>