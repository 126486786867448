import { EncryptStorage } from "encrypt-storage";

const options = {
  storageType: 'sessionStorage'
}

export const encryptedStorage = new EncryptStorage(
  process.env.VUE_APP_SECRET_KEY,
  options
);
