<template>
    <CustomModal title="Bevor Du einziehst...">
        <div class="check-clean-modal__modal" >
        <a class="check-clean-modal__bookIcon">
            <img :src="require('../assets/images/icon-book.svg')" alt="Lagerwunder" />
        </a>
        <div class="check-clean-modal__message">Wir bitten dich höflich, die Garage/den Boxraum vor dem Einzug zu überprüfen. 
            Es ist wichtig sicherzustellen, dass der Raum sauber und für deine Nutzung bereit ist.</div>
        </div>
        <button class="check-clean-modal__button" @click="closeModal()">Ich verstehe</button>
    </CustomModal>
</template>
<script> 
import CustomModal from "./CustomModal.vue";

export default {
    components: {
        CustomModal
    },
    props: {
        closeModal: { type: Function, required: true }
    }
}
</script>