<template>
  <ProgressBar :stage="5" />
  <CheckCleanModal v-if="showCheckCleanModal" :closeModal="() => {this.showCheckCleanModal = false}"/>
  <MainContainer>
    <div class="step5__container">
      <img class="step5__icon" src="../assets/images/success.svg" alt="Success">
      <h1>{{ storage.name }} wurde gebucht!</h1>
      <div class="step5__storage-card">
        <h2 class="step5__storage-card-title">Buchungsübersicht</h2>
        <p class="step5__info">Ausgewähltes Lager:<span>{{ storage.name }}</span></p>
        <p class="step5__info">B x T x H:<span>{{ storage.width }}m x {{ storage.depth }}m x {{
          storage.height
        }}m</span></p>
        <p class="step5__info">Volumen:<span>{{ storage.volume }}m<sup>3</sup></span></p>
        <p class="step5__info">Fläche:<span>{{ storage.area }}m<sup>2</sup></span></p>
        <p class="step5__info">Stockwerk:<span>{{ storage.floor }}</span></p>
        <p v-if="storage.type === 'Garage'" class="step5__info">Beheizt:<span>{{ isHeatedText }}</span></p>
        <p v-if="storage.isEnergyConsumption && storage.type === 'Garage'" class="storage-card__info">Inkl. Stromverbrauch/Jahr:<span>{{
          storage.energyConsumptionLimit }}kWh</span></p>        
        <p class="step5__storage-card-consumptionInformation" v-if="storage.isEnergyConsumption && storage.type === 'Garage'">Zusätzlicher Stromverbrauch
            wird verbrauchsabhängig abgerechnet</p>
        <p class="step5__info">Bezugstermin:<span>{{ startingDate }}</span></p>
        <p class="step5__info">Einmalige Kaution:<span>{{ storage.priceDeposit }}€</span></p>
        <p v-if="!(userData.entitledToDeduction || storageType === 'Garage')" class="step5__info">{{ pricingPeriodLabel }} Miete:<span
            class="step5__storage-card-price">{{ price.toFixed(2) }}€</span></p>
        <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step5__info">{{ pricingPeriodLabel }}
          Miete:<span>{{ price.toFixed(2) }}€</span></p>
        <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step5__info">Mehrwertsteuer
          ({{ storage.vat }}%):<span>{{ vat.toFixed(2) }}€</span></p>
        <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step5__info">{{ pricingPeriodLabelTotalPrice }} Gesamtpreis:<span
            class="step5__storage-card-price">{{ totalPrice.toFixed(2) }}€</span></p>
      </div>
      <h2 class="step5__subtitle">Deine Daten</h2>
      <p class="step5__info">Name: <span>{{ userData.firstName }} {{ userData.lastName }}</span></p>
      <p class="step5__info">E-mail: <span>{{ userData.email }}</span></p>
      <p class="step5__info">Mobilnummer: <span>{{ userData.phone }}</span></p>
      <p class="step5__info">Adresse: <span>{{ userData.street }}, {{ userData.city }},
          {{ userData.zip }}</span></p>
      <p v-if="userData.isBusiness" class="step5__info">Firmenname: <span>{{ userData.company }}</span></p>
    </div>
  </MainContainer>
  <FormButtons :rightButton="{ label: 'Zurück zur Website', icon: true, onClick: next }" />
</template>

<script>
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { PRICING_PERIOD_TO_LABEL_MAP_RENT, PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE, STORAGE_BOOKING_STAGES } from "@/config/constants";
import store from "@/store";
import { calculateVAT, calculatePrice } from "../utils/price";
import { RouterPaths } from "@/router/index";
import CheckCleanModal from '@/components/CheckCleanModal.vue';

export default {
  name: 'step5_Success',
  components: {
    ProgressBar,
    FormButtons,
    MainContainer,
    CheckCleanModal
  },
  methods: {
    next() {
      store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.UNBEGAN);
      store.actions.saveStateLocally();
      this.$router.push(RouterPaths.ROOT);
    }
  },
  setup() {
  },
  mounted() {
    store.actions.setTimerStartDate(null);
    store.actions.saveStateLocally();
  },
  data() {
    return {
      location: store.state.locationId,
      startingDate: store.state.date,
      storage: store.state.storage,
      storageType: store.state.storageType,
      pricingPeriod: store.state.pricingPeriod,
      pricingPeriodLabel: PRICING_PERIOD_TO_LABEL_MAP_RENT[store.state.pricingPeriod],
      pricingPeriodLabelTotalPrice: PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE[store.state.pricingPeriod],
      userData: store.state.user,
      authId: store.state.authId,
      sfBookingId: store.state.sfBookingId,
      showCheckCleanModal: true
    };
  },
  computed: {
    price() {
      const priceMap = {
        week: this.storage.priceWeekly,
        month: this.storage.priceMonthly,
        year: this.storage.priceYearly
      }
      return priceMap[this.pricingPeriod];
    },
    vat() {
      return calculateVAT(this.storage.vat, this.price);
    },
    totalPrice() {
      return calculatePrice(this.price, this.storage.vat, true, 0)
    },
    isHeatedText() {
      return this.storage.heated ? 'ja' : 'nein';
    },
  }
}
</script>
