import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "@vuepic/vue-datepicker/dist/main.css";
import "./assets/styles/main.css";

const app = createApp(App);

app.use(VueAxios, axios);

app.provide("store", store);
app.use(router).mount("#app");
