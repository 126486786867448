<template>
    <div class="text-input">
        <label class="text-input__label" :for="title.replace(/\s/g, '')">{{ title }}</label>
        <input :id="title.replace(/\s/g, '')" v-model="inputVal" class="text-input__input"
            :class="{ 'text-input__input--error': !!error }" type="text" :disabled="disabled">
        <p v-if="error" class="text-input__error">{{ error }}</p>
    </div>
</template>
<script>

export default {
    props: { modelValue: String, title: String, error: String, disabled: Boolean },
    computed: {
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    }
}
</script>