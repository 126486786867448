<template>
    <div class="fail-screen">
        <img v-if="imageVariant === 'generic'" class="fail-screen__img"
            :src="require('../assets/images/storage-search.svg')" alt="Generic error icon">
        <img v-if="imageVariant === 'storageSearchFail'" class="fail-screen__img"
            :src="require('../assets/images/storage-search.svg')" alt="Search error icon">
        <img v-if="imageVariant === '404'" class="fail-screen__img" :src="require('../assets/images/404.svg')"
            alt="404 error icon">
        <h1 class="fail-screen__msg">{{ msg }}</h1>
        <button class="prime-button" @click="buttonOnClick">{{ buttonLabel }}</button>
    </div>
</template>
<script>
export default {
    props: { msg: String, buttonLabel: String, buttonOnClick: Function, imageVariant: { type: String, default: '404' } }
}
</script>