<template>
    <CommonFailScreen imageVariant="generic"
        msg="Entschuldigung, es gab ein Problem beim Laden der Seite. Bitte versuchen Sie es später erneut."
        buttonLabel="Zurück" :buttonOnClick="goToMainPage" />
</template>
  
<script>
import CommonFailScreen from '@/components/CommonFailScreen.vue';
import { RouterPaths } from "@/router/index";

export default {
    name: 'GenericErrorPage',
    components: {
        CommonFailScreen
    },
    methods: {
        goToMainPage() {
            this.$router.push(RouterPaths.BOX_SEARCH)
        }
    }
}
</script>
  