<template>
    <CommonFailScreen msg="Die Seite, die Sie suchen, existiert nicht" buttonLabel="Zurück zur Suche"
        :buttonOnClick="goToMainPage" imageVariant="404" />
</template>
  
<script>
import CommonFailScreen from '@/components/CommonFailScreen.vue';
import { RouterPaths } from "@/router/index";

export default {
    name: 'ErrorPage404',
    components: {
        CommonFailScreen
    },
    methods: {
        goToMainPage() {
            this.$router.push(RouterPaths.BOX_SEARCH)
        }
    }
}
</script>
  