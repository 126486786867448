<template>
    <div class="custom-modal__container" @wheel.self.prevent @touchmove.self.prevent @scroll.self.prevent
        @click="onClose()">
        <div class="custom-modal" @click.stop>
            <div class="custom-modal__header">
                <h2 class="custom-modal__title">{{ title }}</h2>
                <button v-if="onClose" class="custom-modal__close" @click="onClose()">
                    <img :src="require('../assets/images/x.svg')" alt="Close">
                </button>
            </div>
            <slot />
        </div>
    </div>
</template>
<script>


export default {
    name: "CustomModal",
    props: { onClose: Function, title: String },
}
</script>