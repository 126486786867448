<template>
    <CustomModal :onClose="onClose" title="Reservierung läuft!">
        <div class="unfinished-booking-modal__content">
            Es scheint, dass eine zuvor begonnene Reservierung nicht abgeschlossen wurde. Möchtest du sie fortsetzen?
        </div>
        <div class="unfinished-booking-modal__footer">
            <button class="unfinished-booking-modal__start-over-button" @click="startOver()">Von vorne beginnen</button>
            <button class="unfinished-booking-modal__finish-button" @click="finishStartedProcess()">Fortsetzen</button>
        </div>
    </CustomModal>
</template>
<script>
import { STORAGE_BOOKING_STAGES, STORAGE_BOOKING_STAGES_URL_MAP, STORAGE_BOOKING_STAGES_WITH_LOCATION_PATH } from "@/config/constants";
import store from "@/store";
import CustomModal from "./CustomModal.vue";
import { getPathWithLocationName } from "@/router";

export default {
    components: {
        CustomModal
    },
    data() {
        return {
            locationName: store.state.locationName
        }
    },
    methods: {
        startOver() {
            store.actions.setTimerStartDate(null);
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.UNBEGAN);
            store.actions.saveStateLocally();
        },
        finishStartedProcess() {
            const stage = store.state.storageBookingStage;
            const path = STORAGE_BOOKING_STAGES_URL_MAP[store.state.storageBookingStage];
            const newRoute = STORAGE_BOOKING_STAGES_WITH_LOCATION_PATH.includes(stage) ? `${this.locationName?.toLowerCase() ?? ""}/${path}` : path;
            this.$router.push(newRoute);
        }
    }
}
</script>