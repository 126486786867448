<template>
    <div class="storage-card">
        <div class="storage-card__header">
            <img class="storage-card__icon"
                :src="storage.type === 'Box' ? require('../assets/images/box.svg') : require('../assets/images/garage.svg')"
                alt="Storage icon">
            <h2 class="storage-card__title">{{ storage.name }}</h2>
            <p class="storage-card__price">{{ storage.priceMonthly }}€&nbsp;/&nbsp;Mon.</p>
        </div>
        <p class="storage-card__info">B x T x H:<span>{{ storage.width }}m x {{ storage.depth }}m x {{
            storage.height
        }}m</span></p>
        <p class="storage-card__info">Volumen:<span>{{ storage.volume }}m<sup>3</sup></span></p>
        <p class="storage-card__info">Fläche:<span>{{ storage.area }}m<sup>2</sup></span></p>
        <p class="storage-card__info">Stockwerk:<span>{{ storage.floor }}</span></p>
        <p v-if="storage.type === 'Garage'" class="storage-card__info">Beheizt:<span>{{ isHeatedText }}</span></p>
        <p v-if="storage.isEnergyConsumption && storage.type === 'Garage'" class="storage-card__info">Inkl.
            Stromverbrauch/Jahr*:<span>{{
                storage.energyConsumptionLimit }}kWh</span></p>
        <button class="storage-card__button" @click="onClick($event)" :tabindex="disableTabindex ? -1 : 0">Dieses Lager
            wählen</button>
    </div>
</template>
<script>
export default {
    props: { storage: Object, onClick: Function, disableTabindex: Boolean },
    computed: {
        isHeatedText() {
            return this.storage.heated ? 'ja' : 'nein';
        }
    }
}
</script>