<template>
    <div class="checkbox-container">
        <label class="checkbox" :class="{ 'checkbox--error': !!error }"><input v-model="checkboxVal" type="checkbox" />
            <slot></slot>
        </label>
        <p v-if="error" class="checkbox__error">{{ error }}</p>
    </div>
</template>
<script>

export default {
    props: { modelValue: Boolean, error: String },
    computed: {
        checkboxVal: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    }
}
</script>