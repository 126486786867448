<template>
    <CommonFailScreen msg="Entschuldigung, Ihre Buchungszeit ist abgelaufen" buttonLabel="Zurück"
        :buttonOnClick="goToMainPage" imageVariant="storageSearchFail" />
</template>
  
<script>
import CommonFailScreen from '@/components/CommonFailScreen.vue';
import store from '@/store';
import { RouterPaths } from "@/router/index";
import { STORAGE_BOOKING_STAGES } from '@/config/constants';

export default {
    name: 'TimeoutPage',
    components: {
        CommonFailScreen
    },
    methods: {
        goToMainPage() {
            store.actions.setTimerStartDate(null)
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.UNBEGAN);
            store.actions.saveStateLocally;
            this.$router.push(RouterPaths.BOX_SEARCH)
        }
    }
}
</script>
  