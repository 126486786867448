<template>
  <div class="main-container">
    <router-view />
  </div>
</template>

<script>
import store from "@/store";
import { RouterPaths } from "@/router/index";


export default {
  name: 'App',
  components: {
  },
  setup() {
    store.actions.loadState();
  },
  mounted() {
    if (window.location.href.includes('/demo')) {
      this.$router.push(RouterPaths.BOX_SEARCH);
      store.actions.setDemo(true)
    }
  }
}
</script>