<template>
  <CustomModal :onClose="onClose" title="Wie möchtest Du zahlen?">
    <p class="pricing-modal__width"></p>
    <p class="pricing-modal__storage-name">Ausgewähltes Lager:<span>{{ storage.name }}</span></p>
    <p class="pricing-modal__info">B x T x H:<span>{{ storage.width }}m x {{ storage.depth }}m x {{
      storage.height
    }}m</span></p>
    <p class="pricing-modal__info">Volumen:<span>{{ storage.volume }}m<sup>3</sup></span></p>
    <p class="pricing-modal__info">Fläche:<span>{{ storage.area }}m<sup>2</sup></span></p>
    <p class="pricing-modal__info">Stockwerk:<span>{{ storage.floor }}</span></p>
    <p v-if="storage.type === 'Garage'" class="pricing-modal__info">Beheizt:<span>{{ isHeatedText }}</span></p>
    <p v-if="storage.isEnergyConsumption && storage.type === 'Garage'" class="pricing-modal__info">Inkl.
      Stromverbrauch/Jahr:<span>
        {{ storage.energyConsumptionLimit }}kWh</span></p>
    <p class="pricing-modal_consumptionInformation" v-if="storage.isEnergyConsumption && storage.type === 'Garage'">
      Zusätzlicher Stromverbrauch
      wird verbrauchsabhängig abgerechnet</p>
    <div class="pricing-modal__options">
      <label class="pricing-modal__option" :class="{ 'pricing-modal__option--checked': pricingPeriod === 'week' }"
        :tabindex="0" @keypress.enter="() => { pricingPeriod = 'week' }">
        <input v-model="pricingPeriod" type="radio" :id="pricingWeekly" name="pricingOption"
          :value="pricingPeriodValues.WEEK" :tabindex="-1" />{{
            storage.priceWeekly
          }} € / Woche
      </label>
      <label class="pricing-modal__option" :class="{ 'pricing-modal__option--checked': pricingPeriod === 'month' }"
        :tabindex="0" @keypress.enter="() => { pricingPeriod = 'month' }">
        <input v-model="pricingPeriod" type="radio" :id="pricingMonthly" name="pricingOption"
          :value="pricingPeriodValues.MONTH" :tabindex="-1" />{{
            storage.priceMonthly
          }} € / Monat
      </label>
      <label class="pricing-modal__option" :class="{ 'pricing-modal__option--checked': pricingPeriod === 'year' }"
        :tabindex="0" @keypress.enter="() => { pricingPeriod = 'year' }">
        <input v-model="pricingPeriod" type="radio" :id="pricingYearly" name="pricingOption"
          :value="pricingPeriodValues.YEAR" :tabindex="-1" />{{
            storage.priceYearly
          }} € / Jahr
      </label>
    </div>
    <p class="pricing-modal_consumptionInformation">Alle Preise exkl. MwSt.</p>
    <button class="pricing-modal__button" @click="next()">Preis bestätigen</button>
  </CustomModal>
</template>
<script>
import store from "@/store";
import { PRICING_PERIOD_VALUES, STORAGE_BOOKING_STAGES } from "@/config/constants";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import CustomModal from "./CustomModal.vue";

export default {
  components: {
    CustomModal
  },
  props: { storage: Object, onClose: Function, onConfirm: Function },
  methods: {
    next() {
      if (!this.storage || !this.pricingPeriod) return;
      store.actions.saveStorage(this.storage);
      store.actions.savePricingPeriod(this.pricingPeriod);
      store.actions.setSfBookingId(null);
      store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.USER_FORM)
      store.actions.setTimerStartDate(new Date());
      store.actions.saveStateLocally();
      this.$router.push(getPathWithLocationName(RouterPaths.CUSTOMER_FORM, this.locationName));
    }
  },
  data() {
    return {
      locationName: store.state.locationName,
      pricingPeriod: store.state.pricingPeriod,
      pricingPeriodValues: PRICING_PERIOD_VALUES,
    }
  },
  computed: {
    isHeatedText() {
      return this.storage.heated ? 'ja' : 'nein';
    }
  }
}
</script>