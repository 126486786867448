import { LOCATION_ROUTE_PARAM_NAME } from "@/config/constants";
import { createRouter, createWebHistory } from "vue-router";
import ErrorPage404 from "../views/ErrorPage404";
import GenericErrorPage from "../views/GenericErrorPage";
import Step0_Start from "../views/Step0_Start.vue";
import Step1_Box from "../views/Step1_Box.vue";
import Step2_Profile from "../views/Step2_Profile.vue";
import Step3_Agreements from "../views/Step3_Agreements";
import Step4_Payment from "../views/Step4_Payment.vue";
import Step5_Summary from "../views/Step5_Summary";
import TimeoutPage from "../views/TimeoutPage.vue";
import {
  bookingRouteGuard,
  boxSelectionRouteGouard,
} from "./customRouteGuards";

export const RouterPaths = {
  ROOT: "/",
  BOX_SEARCH: "/suchkriterien",
  BOX_SELECTION: `/${LOCATION_ROUTE_PARAM_NAME}/boxenauswahl`,
  CUSTOMER_FORM: `/${LOCATION_ROUTE_PARAM_NAME}/kundendaten`,
  CONTRACT: `/${LOCATION_ROUTE_PARAM_NAME}/vertrag`,
  PAYMENT: `/${LOCATION_ROUTE_PARAM_NAME}/zahlung`,
  SUMMARY: `/${LOCATION_ROUTE_PARAM_NAME}/bestaetigung/zusammenfassung`,
  UNKNOWN: "/:pathMatch(.*)*",
  ERROR: "/fehler",
  TIMEOUT: "/auszeit",
};

export const getPathWithLocationName = (path, locationName) => {
  return path.replace(LOCATION_ROUTE_PARAM_NAME, locationName.toLowerCase());
};

const routes = [
  {
    path: RouterPaths.ROOT,
    redirect: RouterPaths.BOX_SEARCH,
  },
  {
    path: RouterPaths.BOX_SEARCH,
    name: "suchkriterien",
    component: Step0_Start,
  },
  {
    path: RouterPaths.BOX_SELECTION,
    name: "boxenauswahl",
    component: Step1_Box,
    beforeEnter: boxSelectionRouteGouard,
  },
  {
    path: RouterPaths.CUSTOMER_FORM,
    name: "kundendaten",
    component: Step2_Profile,
    beforeEnter: bookingRouteGuard,
  },
  {
    path: RouterPaths.CONTRACT,
    name: "vertrag",
    component: Step3_Agreements,
    beforeEnter: bookingRouteGuard,
  },
  {
    path: RouterPaths.PAYMENT,
    name: "zahlung",
    component: Step4_Payment,
    beforeEnter: bookingRouteGuard,
  },
  {
    path: RouterPaths.SUMMARY,
    name: "zusammenfassung",
    component: Step5_Summary,
    beforeEnter: bookingRouteGuard,
  },
  {
    path: RouterPaths.UNKNOWN,
    name: "404",
    component: ErrorPage404,
  },
  {
    path: RouterPaths.ERROR,
    name: "fehler",
    component: GenericErrorPage,
  },
  {
    path: RouterPaths.TIMEOUT,
    name: "auszeit",
    component: TimeoutPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
