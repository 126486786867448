<template>
    <LoaderComponent v-if="paymentStatus === PAYMENT_STATUS.IN_PROGRESS" />
    <Step5_Success v-if="paymentStatus === PAYMENT_STATUS.SUCCESS" />
    <Step5_Fail v-if="paymentStatus === PAYMENT_STATUS.FAILED" />
</template>

<script>
import Step5_Success from "./Step5_Success.vue";
import Step5_Fail from "./Step5_Fail.vue";
import store from "@/store";
import { STRIPE_PUBLISHKEY_BY_LOCATIONID_MAP } from "@/config/constants";

const PAYMENT_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED"
}

export default {
    name: 'Step5_Summary',
    components: {
        Step5_Success,
        Step5_Fail,
    },
    computed: {
        PAYMENT_STATUS() {
            return PAYMENT_STATUS;
        }
    },
    data() {
        return {
            stripe: null,
            paymentStatus: PAYMENT_STATUS.IN_PROGRESS
        }
    },
    methods: {
        async getPaymentStatus() {
            const clientSecret = new URLSearchParams(window.location.search).get(
                'payment_intent_client_secret'
            );
            const result = await this.stripe.retrievePaymentIntent(clientSecret);
            return result.paymentIntent.status
        },
        async verifyPayment() {
            const paymentStatus = await this.getPaymentStatus();
            if (paymentStatus === "succeeded") {
                this.paymentStatus = PAYMENT_STATUS.SUCCESS
            }
            else if (paymentStatus === "processing") {
                setTimeout(this.verifyPayment(), 500);
            }
            else {
                this.paymentStatus = PAYMENT_STATUS.FAILED
            }
        }
    },
    async mounted() {
        this.stripe = window.Stripe(STRIPE_PUBLISHKEY_BY_LOCATIONID_MAP[store.state.locationId]);
        this.verifyPayment();
    }
}
</script>
