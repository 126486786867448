<template>
    <ProgressBar :stage="4" />
    <MainContainer>
        <CommonFailScreen imageVariant="generic" msg="Es gab ein Problem mit der Zahlung" buttonLabel="Zurück"
            :buttonOnClick="back" />
    </MainContainer>
    <FormButtons :leftButton="{ label: 'Zurück', icon: true, onClick: back }" />
</template>

<script>
import CommonFailScreen from "@/components/CommonFailScreen.vue";
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import store from "@/store";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import { STORAGE_BOOKING_STAGES } from "@/config/constants";

export default {
    name: 'Step5_Fail',
    components: {
        ProgressBar,
        FormButtons,
        MainContainer,
        CommonFailScreen
    },
    data() {
        return {
            locationName: store.state.locationName
        }
    },
    methods: {
        back() {
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.PAYMENT);
            store.actions.saveStateLocally();
            this.$router.push(getPathWithLocationName(RouterPaths.PAYMENT, this.locationName));
        }
    }
}
</script>
