<template>
    <div class="tooltip">
        <p class="tooltip__text">{{ text }}
            <button @mouseenter="show" @mouseleave="hide" @click="toggle">
                <img :src="require('../assets/images/tooltip.svg')" alt="Info" />
            </button>
        </p>
        <Transition name="tooltip-fade">
            <div v-if="isVisible" class="tooltip__info">
            <p>
                {{ infoText }}
            </p>
        </div>
        </Transition>
    </div>
</template>
<script>

export default {
    props: { text: String, infoText: String },
    methods: {
        show() {
            this.isVisible = true
        },
        hide() {
            this.isVisible = false
        },
        toggle() {
            this.isVisible = !this.isVisible
        }
    },
    data() {
        return {
            isVisible: false
        }
    }
}
</script>