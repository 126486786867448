// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/chevron-right.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/check-green.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@use \"../colors.css\";.progress-bar{width:100%;padding:1rem 0;border-bottom:1px solid var(--gray200);overflow-x:hidden}.progress-bar__logo{width:200px;display:block;margin-left:auto;margin-right:auto;margin-top:20px}.progress-bar__list{margin:0;list-style:none;display:flex;justify-content:center;align-items:center;gap:3rem;white-space:nowrap;position:relative}.progress-bar__element{color:var(--gray500);position:relative;transition-duration:.3s}.progress-bar__element:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:1rem;height:1rem;position:absolute;top:50%;right:-2rem;transform:translateY(-50%)}.progress-bar__element:last-child:after{display:none}.progress-bar__element--active{padding:.75rem 1rem;border-radius:.75rem;background-color:var(--primary50);color:var(--primary700)}.progress-bar__element--passed{color:var(--green);font-weight:700;padding-left:1.5rem}.progress-bar__element--passed:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");width:1rem;height:1rem;position:absolute;top:50%;left:0;transform:translateY(-50%)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
