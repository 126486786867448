<template>
    <div class="booking-timer">
        <p>
            <img class="booking-timer__hourglass-icon" :src="require('../assets/images/hourglass.svg')"
                alt="Hourglass icon">
                Bitte reserviere vor Ablauf der verbleibenden Zeit:
            <span class="booking-timer__countdown">
                {{ Math.max(minutesLeft, 0) }}:{{ Math.max(secondsLeft, 0).toFixed(0).toString().padStart(2, '0') }}
            </span>
        </p>
    </div>
</template>
<script>
import { RouterPaths } from "@/router";
import { useRouter } from "vue-router";
import store from "@/store";
import { BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS } from "../config/constants";

export default {
    methods: {
        handleTimeEnd() {
            this.logout();
        },
        getMinutesLeft(timeInMilliseconds) {
            return Math.floor(timeInMilliseconds / 60000)
        },
        getSecondsLeft(timeInMilliseconds) {
            return ((timeInMilliseconds % 60000) / 1000)
        },
        calculateRemainingTime(countingStartDate, countedTimeInMilliseconds) {
            const timePassed = new Date() - countingStartDate
            return countedTimeInMilliseconds - timePassed;
        }
    },
    setup() {
        const router = useRouter();
        store.actions.loadState();

        return {
            logout: () => {
                localStorage.setItem('app_state', null);
                router.push(RouterPaths.TIMEOUT);
            }
        };
    },
    mounted() {
        this.intervalId = setInterval(() => {
            const timeLeft = this.calculateRemainingTime(this.countingStartDate, BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS)
            this.minutesLeft = this.getMinutesLeft(timeLeft);
            this.secondsLeft = this.getSecondsLeft(timeLeft);
            if (timeLeft <= 0) {
                this.handleTimeEnd()
            }
        }, 1000)
    },
    unmounted() {
        clearInterval(this.intervalId)
    },
    data() {
        const countingStartDate = store.state.timerStartDate ?? new Date();
        const timeLeft = this.calculateRemainingTime(countingStartDate, BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS)
        const minutesLeft = this.getMinutesLeft(timeLeft);
        const secondsLeft = this.getSecondsLeft(timeLeft);
        return {
            minutesLeft,
            secondsLeft,
            intervalId: null,
            countingStartDate
        }
    },
}
</script>