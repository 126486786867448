import store from "@/store";
import {
  STORAGE_BOOKING_STAGES_URL_MAP,
  STORAGE_BOOKING_STAGES,
} from "@/config/constants";
import { RouterPaths } from "@/router/index";

const storageBookingStagesInOrder = [
  STORAGE_BOOKING_STAGES.UNBEGAN,
  STORAGE_BOOKING_STAGES.USER_FORM,
  STORAGE_BOOKING_STAGES.CONTRACT,
  STORAGE_BOOKING_STAGES.PAYMENT,
  STORAGE_BOOKING_STAGES.SUMMARY,
];

if (
  storageBookingStagesInOrder.length !==
  Object.keys(STORAGE_BOOKING_STAGES).length
) {
  throw new Error("Not all booking stages are included in route guard.");
}

const checkIfNextRouteIsAheadOfStage = (nextRouteStage) => {
  const currentStageIndex = storageBookingStagesInOrder.findIndex(
    (e) => e === store.state.storageBookingStage
  );
  const nextRouteStageIndex = storageBookingStagesInOrder.findIndex(
    (e) => e === nextRouteStage
  );
  return nextRouteStageIndex > currentStageIndex;
};

const checkIfShouldRedirectToSummary = (nextRouteStage) => {
  const isStageSummary =
    store.state.storageBookingStage === STORAGE_BOOKING_STAGES.SUMMARY;
  const isNextRouteSummary = nextRouteStage === STORAGE_BOOKING_STAGES.SUMMARY;
  return isStageSummary && !isNextRouteSummary;
};

const findBookingStageByPath = (path) => {
  for (let key in STORAGE_BOOKING_STAGES_URL_MAP) {
    if (path.includes(STORAGE_BOOKING_STAGES_URL_MAP[key])) {
      return key;
    }
  }
  return undefined;
};

export const bookingRouteGuard = async (to, from, next) => {
  const nextRouteStage = findBookingStageByPath(to.path);
  const isNextRouteAheadOfStage =
    checkIfNextRouteIsAheadOfStage(nextRouteStage);
  const redirectFromSummary = checkIfShouldRedirectToSummary(nextRouteStage);
  const redirectToRootPage = isNextRouteAheadOfStage || redirectFromSummary;

  if (!redirectToRootPage) {
    store.actions.setStorageBookingStage(
      redirectFromSummary ? STORAGE_BOOKING_STAGES.UNBEGAN : nextRouteStage
    );
    store.actions.saveStateLocally();
  }

  next(redirectToRootPage ? RouterPaths.ROOT : undefined);
};

export const boxSelectionRouteGouard = async (to, from, next) => {
  const redirectToRoot =
    store.state.storageBookingStage !== STORAGE_BOOKING_STAGES.UNBEGAN;
  next(redirectToRoot ? RouterPaths.ROOT : undefined);
};
