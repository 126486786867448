<template>
    <main class="main">
        <Transition v-if="showContent" :name="transitionName" appear>
            <slot></slot>
        </Transition>
        <LoaderComponent v-if="showLoader && !showContent" />
    </main>
</template>
<script>
import LoaderComponent from './LoaderComponent.vue';

export default {
    props: {
        showContent: {
            type: Boolean,
            default: true,
        },
        showLoader: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        const prevStepNumber = this.$router.options.history.state.back?.match(/\d+/g)?.join("") || 0;
        const currStepNumber = this.$router.options.history.state.current?.match(/\d+/g)?.join("") || 1;
        return {
            transitionName: Number(currStepNumber) < Number(prevStepNumber) ? "router-left" : "router-right"
        };
    },
    components: { LoaderComponent }
}
</script>