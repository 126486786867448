<template>
    <div class="demo" v-if="demo && demo === true"></div>
    <a class="progress-bar__logo" href="https://lagerwunder.com/">
        <img :src="require('../assets/images/logo.svg')" alt="Lagerwunder" />
    </a>
    <div class="progress-bar">
        <ul class="progress-bar__list" :style="{ left: listOffset + 'px' }">
            <li class="progress-bar__element" :class="getElementClassObject(0)">Suchkriterien</li>
            <li class="progress-bar__element" :class="getElementClassObject(1)">Boxenauswahl</li>
            <li class="progress-bar__element" :class="getElementClassObject(2)">Kundendaten</li>
            <li class="progress-bar__element" :class="getElementClassObject(3)">Vertrag</li>
            <li class="progress-bar__element" :class="getElementClassObject(4)">Zahlung</li>
            <li class="progress-bar__element" :class="getElementClassObject(5)">Zusammenfassung</li>
        </ul>
    </div>
</template>
<script>

import store from "@/store";

export default {
    props: { stage: Number },
    methods: {
        getElementClassObject(s) {
            return {
                'progress-bar__element--active': s === this.stage,
                'progress-bar__element--passed': s < this.stage
            }
        },
        updateListOffset() {
            if (window.innerWidth < 768) {
                const listElement = document.querySelector('.progress-bar__list');
                const activeEl = document.querySelector('.progress-bar__element--active');
                const targetOffset = (listElement.offsetWidth - activeEl.offsetWidth) / 2;
                this.listOffset = targetOffset - activeEl.offsetLeft;
            }
            else {
                this.listOffset = 0;
            }
        }
    },
    mounted() {
        this.updateListOffset();
        window.addEventListener('resize', this.updateListOffset)
    },
    unmounted() {
        window.removeEventListener('resize', this.updateListOffset)
    },
    data() {
        return {
            listOffset: 0,
            demo: store.state.demo
        }
    }
}
</script>