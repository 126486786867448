<template>
    <ProgressBar :stage="3" />
    <BookingTimer />
    <MainContainer class="step3__main" :showContent="rendered" :showLoader="true">
        <div v-if="this.pdfUrl" class="step3__container">
            <object class="step3__pdf-object" :data="this.pdfUrl" type="application/pdf" width="100%" height="600px">
                <a :href="this.pdfUrl">
                    <button class="step3__pdf-button">
                        <img class="step3__pdf-download-icon" :src="require('../assets/images/download.svg')"
                            alt="Download icon">
                        Vertrag herunterladen
                    </button>
                </a>
            </object>
            <a v-if="this.isAppleDevice" :href="this.pdfUrl" class="step3__pdf-apple-devices">
                <button class="step3__pdf-button step3__pdf-button-apple">
                    <img class="step3__pdf-download-icon step3__pdf-download-icon-apple"
                        :src="require('../assets/images/download.svg')" alt="Download icon">
                    Vertrag herunterladen
                </button>
            </a>
            <div class="step3__container__checkbox">
                <CommonCheckbox v-model="acceptContract" :error="errorMsg">
                    <span> Hiermit bestätige ich, dass ich den Vertrag gelesen habe und akzeptiere. </span>
                </CommonCheckbox>
            </div>
        </div>
        <CommonFailScreen v-if="showErrorComponent"
            msg="Ups, etwas ist schief gelaufen. Bitte versuchen Sie es noch einmal."
            buttonLabel="Versuchen Sie es mit anderen Kriterien" :buttonOnClick="back" imageVariant="storageSearchFail" />
    </MainContainer>
    <FormButtons v-if="this.pdfUrl" class="step3__buttons" :leftButton="{ label: 'Zurück', icon: true, onClick: back }"
        :rightButton="{ label: 'Weiter zur Zahlung', icon: true, onClick: next }" />
</template>

<script>
import CommonFailScreen from "@/components/CommonFailScreen.vue";
import BookingTimer from "@/components/BookingTimer.vue";
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import store from "@/store";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import { STORAGE_BOOKING_STAGES } from "@/config/constants";
import CommonCheckbox from "@/components/CommonCheckbox.vue";

export default {
    name: 'Step3_Agreements',
    components: {
        FormButtons,
        ProgressBar,
        MainContainer,
        BookingTimer,
        CommonCheckbox,
        CommonFailScreen
    },
    methods: {
        async createBooking() {
            //user
            let userUpdateSuccess = false;
            const userPayload = {
                email: this.userData.email,
                firstName: this.userData.firstName,
                lastName: this.userData.lastName,
                isBusiness: this.userData.isBusiness,
                company: this.userData.company,
                entitledToDeduction: this.userData.entitledToDeduction,
                phone: this.userData.phone,
                street: this.userData.street,
                city: this.userData.city,
                country: this.userData.country,
                zip: this.userData.zip,
                birthDate: this.userData.birthDate,
                birthPlace: this.userData.birthPlace,
                companyRegistrationNumber: this.userData.companyRegistrationNumber,
                districtCourt: this.userData.districtCourt,
                locationId: store.state.locationId
            };
            if (!this.authId) {
                await this.axios.post(process.env.VUE_APP_API_BASE_URL + '/user', userPayload)
                    .then(res => {
                        if (res.status === 200) {
                            this.authId = res.data.authId;
                            store.actions.setAuthId(res.data.authId);
                            console.log("SF User created!");
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.showErrorComponent = true;
                    });

            }

            if (this.authId) {
                userPayload.authId = this.authId;
                await this.axios.put(process.env.VUE_APP_API_BASE_URL + '/user', userPayload)
                    .then((res) => {
                        if (res.status === 200) {
                            userUpdateSuccess = true;
                            console.log("SF User updated!");
                        } else {
                            userUpdateSuccess = false;
                            this.showErrorComponent = true;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.showErrorComponent = true;
                    });
            }

            //booking
            if (!this.sfBookingId && userUpdateSuccess) {
                const invoicePeriodMap = {
                    week: 'weekly',
                    month: 'monthly',
                    year: 'yearly'
                }
                const bookingPayload = {
                    authId: this.authId,
                    storageId: this.storage.storageId,
                    dateFrom: this.startDate,
                    invoicePeriod: invoicePeriodMap[this.pricingPeriod],
                    locationId: store.state.locationId
                }
                await this.axios.post(process.env.VUE_APP_API_BASE_URL + '/booking', bookingPayload)
                    .then(res => {
                        store.actions.setSfBookingId(res.data.bookingId);
                        store.actions.saveStateLocally();
                        this.sfBookingId = res.data.bookingId;
                    })
                    .catch(err => {
                        this.showErrorComponent = true;
                    })
            }

            //contract
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + '/contract', {
                responseType: 'blob',
                params: { bookingId: this.sfBookingId, authId: this.authId }
            })
                .then(res => {
                    this.isAppleDevice = /iPhone|iPad/i.test(navigator.userAgent);
                    this.pdfUrl = URL.createObjectURL(res.data);
                })
                .catch(err => {
                    this.showErrorComponent = true;
                })

            this.rendered = true;
        },
        async sign() {
            if (!this.sfBookingId) return;
            const payload = {
                authId: this.authId,
                bookingId: this.sfBookingId
            }
            await this.axios.post(process.env.VUE_APP_API_BASE_URL + '/contract/sign', payload)
                .catch(err => { console.log(err); this.showErrorComponent = true; })
        },
        checkout() {
            this.$router.push(getPathWithLocationName(RouterPaths.PAYMENT, this.locationName));
        },
        async next() {
            if (!this.acceptContract) {
                this.errorMsg = "Bitte akzeptieren Sie den Vertrag";
                return;
            }
            await this.sign();
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.PAYMENT)
            store.actions.saveStateLocally();
            this.checkout();
        },
        back() {
            store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.USER_FORM)
            store.actions.saveStateLocally();
            window.location.pathname =getPathWithLocationName(RouterPaths.CUSTOMER_FORM, this.locationName);
        },
    },
    mounted() {
        this.createBooking();
    },
    data() {
        return {
            userData: store.state.user,
            sfUserExists: store.state.sfUserExists,
            sfBookingId: store.state.sfBookingId,
            authId: store.state.authId,
            storage: store.state.storage,
            locationName: store.state.locationName,
            startDate: store.state.date,
            pricingPeriod: store.state.pricingPeriod,
            acceptContract: false,
            errorMsg: null,
            pdfUrl: null,
            rendered: false,
            showErrorComponent: false,
            isAppleDevice: false
        }
    },
}
</script>
