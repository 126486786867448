<template>
    <div class="buttons-container" :style="{ justifyContent: justify || leftButton ? 'space-between' : 'flex-end' }">
        <button :disabled="leftButton.disabled" v-if="leftButton" class="sec-button"
            :class="{ 'sec-button--icon': leftButton.icon }" @click="leftButton.onClick($event)"
            :tabindex="disableTabindex ? -1 : 0">{{ leftButton.label }}</button>
        <button :disabled="rightButton.disabled" v-if="!msg && rightButton" class="prime-button"
            :class="{ 'prime-button--icon': rightButton.icon }" @click="rightButton.onClick($event)"
            :tabindex="disableTabindex ? -1 : 0">{{ rightButton.label }}</button>
        <p v-if="msg" class="buttons-msg">{{ msg }}</p>
    </div>
</template>
<script>
export default {
    props: { msg: String, justify: String, leftButton: Object, rightButton: Object, disableTabindex: Boolean },
}
</script>